<script setup lang="ts">
const route = useRoute()
</script>

<template>
  <div class="min-h-screen justify-between flex flex-col">
    <slot />
    <footer class="footer p-8 bg-white text-neutral-content">
      <div class="flex items-center gap-4 justify-center w-full">
        <div v-if="route.name !== 'login'">
          <UButton to="/login" size="xs" label="Login" variant="outline" color="gray" />
        </div>
        <div>
          <NuxtLink to="/privacy">Privacy</NuxtLink>
        </div>
        <div>
          <NuxtLink to="/imprint">Imprint</NuxtLink>
        </div>
        <div>
          &copy; {{ (new Date()).getFullYear() }}
        </div>
      </div>
    </footer>
  </div>
</template>
